<template>
  <ReportSimpleDataTable
    :urls="urls"
    :mandatory-filter="[
      {
        name: 'putaway_date_range',
        title: 'Date range',
        valueFieldName: '.select',
      },
    ]"
  />
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ReportSimpleDataTable from "@/own/components/reports/ReportSimpleDataTable.vue";
export default {
  name: "PutawayDetails",
  components: { ReportSimpleDataTable },
  data: () => ({
    urls: {
      data_url: "/reports/reportPutawayDetails",
      show_url: "/reports/reportPutawayDetails/show",
    },
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
